import { render, staticRenderFns } from "./PersonChecklists.vue?vue&type=template&id=d0c43226&scoped=true&"
import script from "./PersonChecklists.vue?vue&type=script&lang=ts&"
export * from "./PersonChecklists.vue?vue&type=script&lang=ts&"
import style0 from "./PersonChecklists.vue?vue&type=style&index=0&id=d0c43226&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0c43226",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCheckbox,VChip,VContainer,VDataTable,VExpandTransition,VIcon,VTab,VTabs})
