var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "my-5",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-tabs', {
    staticClass: "mt-10",
    attrs: {
      "background-color": "background"
    }
  }, _vm._l(_vm.state.checklistTemplates, function (tab) {
    return _c('v-tab', {
      key: tab.id,
      on: {
        "click": function click($event) {
          return _vm.functions.reloadActiveChecklist(tab.id);
        }
      }
    }, [_vm._v(" " + _vm._s(tab.title) + " ")]);
  }), 1), _vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('v-btn', {
    staticClass: "my-5 rounded-lg white",
    attrs: {
      "text": "",
      "elevation": "0",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.listeners.onToggleFilterDropdown();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiFilter) + " ")]), _vm._v(" " + _vm._s(_vm.$t('misc.filter')) + " "), _c('v-icon', {
    attrs: {
      "right": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.state.isFilterDropdownOpen ? _vm.icons.mdiChevronUp : _vm.icons.mdiChevronDown) + " ")])], 1) : _vm._e(), _c('v-expand-transition', {
    attrs: {
      "mode": "in-out"
    }
  }, [_vm.state.isFilterDropdownOpen ? _c('PersonChecklistsFilterBar', {
    staticClass: "d-flex align-center",
    attrs: {
      "fields": _vm.constants.FILTER_FIELDS
    }
  }) : _vm._e()], 1), _c('v-data-table', {
    staticClass: "rounded-lg elevation-0 row-pointer",
    attrs: {
      "fixed-header": "",
      "height": "65vh",
      "hide-default-header": "",
      "headers": _vm.constants.TABLE_HEADERS,
      "items": _vm.state.checklists,
      "loading": _vm.state.isLoadingChecklists,
      "is-full-width": "",
      "options": _vm.state.vuetifyTableOptions,
      "server-items-length": _vm.state.checklists.length
    },
    on: {
      "update:options": [function ($event) {
        return _vm.$set(_vm.state, "vuetifyTableOptions", $event);
      }, function ($event) {
        return _vm.functions.debouncedCb();
      }],
      "dblclick:row": function dblclickRow() {
        var arguments$1 = arguments;

        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments$1[_key];
        }

        return _vm.listeners.onDblClickRow(args);
      }
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(_ref) {
        var headers = _ref.props.headers;
        return [_c('thead', [_c('tr', _vm._l(headers, function (header) {
          return _c('th', {
            key: header.text,
            staticClass: "parent__wrapper"
          }, [_c('div', {
            class: "".concat(!_vm.functions.excludeHorizontalHeader(header.text) && 'horizontal__header')
          }, [_c('div', {
            class: "".concat(_vm.functions.excludeHorizontalHeader(header.text) && 'outer-wrapper')
          }, [_c('div', {
            class: "".concat(_vm.functions.excludeHorizontalHeader(header.text) && 'header-title')
          }, [_vm._v(" " + _vm._s(header.text) + " ")])])])]);
        }), 0)])];
      }
    }, {
      key: "item.person.name",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('td', {
          staticClass: "personCol--min-width"
        }, [_c('span', [_vm._v(" " + _vm._s(item.person.name.split(' ').splice(0, 2).join(' ')) + " ")]), _c('br'), _c('span', [_vm._v(" " + _vm._s(item.person.name.split(' ').splice(2).join(' ')) + " ")])])];
      }
    }, {
      key: "item.contractStart",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(item.contractStart)) + " ")]), _c('br'), _c('span', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(item.contractEnd)) + " ")])];
      }
    }, {
      key: "item.activeProfessionalUnits",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return _vm._l(item.activeProfessionalUnits, function (profUnit) {
          return _c('span', {
            key: profUnit.id + Math.round(Math.random() * 1000)
          }, [_c('v-chip', {
            staticClass: "mr-1 my-1",
            attrs: {
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(profUnit.name) + " ")])], 1);
        });
      }
    }, {
      key: "item.countNotes",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm.functions.hasSufficientRights(_vm.constants.Rights.NOTE_READ_ALL) || _vm.functions.hasSufficientRights(_vm.constants.Rights.NOTE_READ_OWN) ? _c('div', [_vm._v(" " + _vm._s(item.countNotes) + " "), _c('v-btn', {
          staticClass: "mx-1",
          attrs: {
            "icon": "",
            "x-small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.listeners.onOpenNotesDialog(item.person.id);
            }
          }
        }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.icons.mdiNotebookEditOutline) + " ")])], 1)], 1) : _vm._e()];
      }
    }, _vm._l(_vm.constants.ENTRY_HEADERS, function (entry) {
      return {
        key: "item.".concat(entry.value),
        fn: function fn(_ref6) {
          var row = _ref6.item;
          return [_c('td', {
            key: entry.value + Math.round(Math.random() * 1000)
          }, [row.checklistEntries.find(function (e) {
            return e.checklistTask.name === entry.text;
          }) ? _c('v-checkbox', {
            staticClass: "ma-0 ml-1 mt-n1",
            attrs: {
              "input-value": row.checklistEntries.find(function (e) {
                return e.checklistTask.name === entry.text;
              }).checklistEntryStatus,
              "readonly": !_vm.functions.hasSufficientRights(_vm.constants.Rights.CHECKLIST_UPDATE),
              "value": _vm.functions.hasStatus(_vm.constants.CHECKLIST_ENTRY_STATUS.OPEN, row.checklistEntries, entry.text),
              "color": _vm.functions.hasStatus(_vm.constants.CHECKLIST_ENTRY_STATUS.NOT_RELEVANT, row.checklistEntries, entry.text) ? 'grey' : '',
              "indeterminate": _vm.functions.hasStatus(_vm.constants.CHECKLIST_ENTRY_STATUS.NOT_RELEVANT, row.checklistEntries, entry.text),
              "hide-details": ""
            },
            on: {
              "click": function click($event) {
                $event.stopPropagation();
              },
              "change": function change($event) {
                _vm.listeners.onChangeChecklistTaskStatus(row.checklistEntries.find(function (e) {
                  return e.checklistTask.name === entry.text;
                }));
              }
            }
          }) : _vm._e()], 1)];
        }
      };
    })], null, true)
  }), _vm.state.isNotesDialogOpen ? _c('CommonNotesDialog', {
    attrs: {
      "notes": _vm.state.personNotes,
      "entity-name": "person",
      "entity-id": _vm.state.activePersonId
    },
    on: {
      "reload-notes": function reloadNotes($event) {
        return _vm.listeners.onReloadNotes();
      },
      "close": _vm.listeners.onCloseNotesDialog
    },
    model: {
      value: _vm.state.isNotesDialogOpen,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isNotesDialogOpen", $$v);
      },
      expression: "state.isNotesDialogOpen"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }